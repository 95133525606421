import React from 'react';
import { IntlContextConsumer, changeLocale, useIntl } from 'gatsby-plugin-intl';

type LanguageProps = {
  icon?: boolean;
  className?: string;
  alternativeLanguages?: {
    path: string;
    languages: { lang: string; uid: string }[];
  };
};
const Language = ({
  icon = false,
  className,
  alternativeLanguages,
}: LanguageProps) => {
  const intl = useIntl();

  return (
    <div className={`m-language-chooser ${className ? className : ''}`}>
      {icon && (
        <svg className="m-language-chooser__icon">
          <use xlinkHref="#language"></use>
        </svg>
      )}
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) => {
          return (
            <form
              className="m-language-chooser__form"
              action="set_language"
              method="post"
            >
              <input name="next" type="hidden" value="" />
              <select
                className="m-language-chooser__select"
                name="language"
                aria-label="Select language"
                onChange={(e) => {
                  if (alternativeLanguages) {
                    const path = alternativeLanguages.languages.find(
                      (element) => element.lang.includes(e.target.value)
                    );
                    const basePath = alternativeLanguages.path;
                    changeLocale(
                      e.target.value,
                      path ? `${basePath}${path.uid}` : null
                    );
                  } else {
                    changeLocale(e.target.value);
                  }
                }}
                value={currentLocale}
              >
                {languages.map((language) => (
                  <option
                    key={`${Date.now}-${language}-picker`}
                    value={language}
                  >
                    {intl.formatMessage({ id: language })}
                  </option>
                ))}
              </select>

              <svg className="m-language-chooser__chevron">
                <use xlinkHref="#chevron-bottom"></use>
              </svg>
            </form>
          );
        }}
      </IntlContextConsumer>
    </div>
  );
};

export default Language;
