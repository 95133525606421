import React from 'react';
import { IntlContextConsumer, changeLocale, useIntl } from 'gatsby-plugin-intl';
import Dropdown from '../../dropdown';

const Language = ({ alternativeLanguages }) => {
  const intl = useIntl();
  return (
    <IntlContextConsumer>
      {({ languages, language: currentLocale }) => {
        const languageOptions = [];
        languages.forEach((lang) =>
          languageOptions.push({
            text: intl.formatMessage({ id: lang }),
            value: lang,
          })
        );
        return (
          <Dropdown
            options={languageOptions}
            onChange={(value) => {
              if (alternativeLanguages) {
                const path = alternativeLanguages.languages.find((element) =>
                  element.lang.includes(value)
                );
                const basePath = alternativeLanguages.path;
                changeLocale(value, path ? `${basePath}${path.uid}` : null);
              } else {
                changeLocale(value);
              }
            }}
            currentValue={currentLocale}
          />
        );
      }}
    </IntlContextConsumer>
  );
};

export default Language;
