import React, { useEffect, useState } from 'react';

import { FormattedMessage } from 'gatsby-plugin-intl';

const NewsletterForm = ({ data }) => {
  const [formValues, setFormValues] = useState({
    email: '',
  });
  const emailRegex = '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$';
  const [hasEmptyEmail, setHasEmptyEmail] = useState(false);
  const [hasValidEmail, setHasValidEmail] = useState(true);
  const [feedback, setFeedback] = useState<{
    message: string;
    type: string;
  } | null>({ message: '', type: '' });

  const disabledForm =
    feedback.type === 'success' || !hasValidEmail || hasEmptyEmail;

  useEffect(() => {
    if (formValues.email) {
      setHasEmptyEmail(false);
      if (formValues.email.match(emailRegex)) {
        setHasValidEmail(true);
      } else {
        setHasValidEmail(false);
      }
    } else {
      setHasValidEmail(true);
      setHasEmptyEmail(true);
    }
  }, [formValues]);

  const handleSubmission = async () => {
    if (!hasValidEmail || hasEmptyEmail) return;
    const hutk = window?.__hsUserToken || null;
    const body = {
      fields: [
        {
          objectTypeId: '0-1',
          name: 'email',
          value: formValues.email,
        },
      ],
      context: {
        hutk,
        pageUri: window.location.href,
        pageName: document.title,
      },
    };
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    };
    const response = await fetch(
      `https://api.hsforms.com/submissions/v3/integration/submit/6092764/f324e048-58a8-4d48-80cd-67fbf899bc19`,
      requestOptions
    );
    if (response.status >= 200 && response.status <= 299) {
      const jsonResponse = await response.json();
      if (jsonResponse.inlineMessage)
        setFeedback({ message: jsonResponse.inlineMessage, type: 'success' });
      setFormValues({ ...formValues, email: '' });
    } else {
      const error = JSON.parse(await response.text());
      if (error.errors) {
        setFeedback({ message: error.errors[0].message, type: 'error' });
      }
    }
  };

  return (
    <div className="m-newsletter-form__form">
      <label
        className={`subscribe ${!hasValidEmail ? 'subscribe--error' : ''}`}
      >
        <input
          className={`subscribe__input`}
          placeholder={data.placeholder}
          id="email"
          name="email"
          value={formValues.email}
          onChange={(e) => {
            setFormValues({ ...formValues, email: e.target.value });
          }}
          type="email"
          required
          pattern={emailRegex}
        />
        <button
          className={`subscribe__label ${
            disabledForm
              ? `subscribe__label--disabled`
              : `subscribe__label--active`
          }`}
          type="submit"
          disabled={disabledForm}
          onClick={() => {
            handleSubmission();
          }}
        >
          {data.ctaText}
        </button>
      </label>
      {feedback.type !== 'success' && (
        <>
          {!hasValidEmail && (
            <label className="subscribe__label--error">
              <FormattedMessage
                id="email-invalid"
                defaultMessage="Please enter a valid email"
              />
            </label>
          )}
        </>
      )}

      {feedback.message && (
        <div className={`subscribe__label--${feedback.type}`}>
          <FormattedMessage
            id="form-invalid"
            defaultMessage={feedback.message}
          />
        </div>
      )}
    </div>
  );
};

export default NewsletterForm;
