import React from 'react';

type LogoProps = {
  className: string;
};
const Logo = ({ className = '' }: LogoProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 475.98 100"
      className={className}
    >
      <title>deeptrue</title>
      <path d="M56.37,0a7,7,0,0,0-7,6.84h0V21.52A31.71,31.71,0,1,0,63.42,47.86h0v-41h-.05A7,7,0,0,0,56.37,0ZM31.71,65.52A17.66,17.66,0,1,1,49.37,47.86,17.66,17.66,0,0,1,31.71,65.52ZM236.44,17.66a31.71,31.71,0,0,0-31.71,31.71h0v41h.05a7,7,0,0,0,14,0h0V75.71a31.71,31.71,0,1,0,17.66-58Zm0,49.37A17.66,17.66,0,1,1,254.1,49.37,17.66,17.66,0,0,1,236.44,67ZM400.56,16.87a7,7,0,0,0-7,6.84h0V52.16h0a14,14,0,0,1-28,0h0V23.71h-.05a7,7,0,0,0-14,0h0V52.16a28,28,0,0,0,56.06,0V23.71h-.05A7,7,0,0,0,400.56,16.87Zm-60.84-.51v-.08h-3.35a27,27,0,0,0-27,27V73.35h.05a7,7,0,0,0,14,0h0V43.29h0a12.91,12.91,0,0,1,12.92-12.92h3.35a7,7,0,1,0,0-14Zm-42,15.57v-.05a7,7,0,0,0,0-14H287V7.1h0a7,7,0,0,0-14,0h0V59.54a20.65,20.65,0,0,0,20.65,20.65h4.07v0a7,7,0,0,0,0-14h-4.07a6.6,6.6,0,0,1-6.6-6.6h0V31.93ZM100,16.77A31.71,31.71,0,1,0,122.37,70.9l0,0A7,7,0,0,0,112.43,61,17.56,17.56,0,0,1,100,66.14c-7.4,0-15.36-4.58-17.19-12H125c3.66,0,6.63-2,6.63-5.66h0A31.71,31.71,0,0,0,100,16.77ZM82.76,42.24c2.17-7.82,9.42-12.32,17.19-12.32,9.44,0,15.29,5.29,16.92,12Zm85.43-25.47A31.71,31.71,0,1,0,190.62,70.9l0,0a7,7,0,0,0-9.9-9.91,17.6,17.6,0,0,1-12.49,5.18c-7.4,0-15.35-4.58-17.19-12h42.27c3.66,0,6.63-2,6.63-5.66h0A31.71,31.71,0,0,0,168.19,16.77ZM151,42.24c2.17-7.82,9.42-12.32,17.19-12.32,9.45,0,15.29,5.29,16.93,12ZM444.14,16.77A31.71,31.71,0,1,0,466.56,70.9l0,0A7,7,0,0,0,456.62,61a17.56,17.56,0,0,1-12.48,5.18c-7.4,0-15.36-4.58-17.19-12h42.27c3.66,0,6.63-2,6.63-5.66h0A31.71,31.71,0,0,0,444.14,16.77ZM427,42.24c2.17-7.82,9.42-12.32,17.19-12.32,9.44,0,15.29,5.29,16.92,12Z"></path>
    </svg>
  );
};

export default Logo;
