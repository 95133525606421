import React, { useState } from 'react';
import { Icon } from '@deeptrue-frontend/ui';

type Option = {
  value: string;
  text: string;
};

type DropdownProps = {
  options: [Option];
  placeholder?: string;
  defaultValue?: string;
  onChange?: Function;
  className: string;
  currentValue: string;
};

const Dropdown = ({
  options,
  placeholder,
  onChange,
  currentValue,
  className = '',
}: DropdownProps) => {
  const [isOpen, setOpen] = useState(false);

  const handleSelect = (value) => {
    setOpen(!isOpen);
    if (onChange) {
      onChange(value);
    }
  };
  const handleToggle = () => setOpen(!isOpen);
  const currencyOptions = options.find(
    (option) => option.value === currentValue
  );

  return (
    <div
      className={`
        m-dropdown
        ${isOpen ? ' m-dropdown--active' : ''}
        ${className ? className : ''}
      `}
      tabIndex={0}
      onBlur={() => setOpen(false)}
    >
      <div className="m-dropdown__input" onClick={handleToggle}>
        {currentValue && (
          <span className="m-dropdown__value">
            {currencyOptions ? currencyOptions.text : null}
          </span>
        )}
        {!currentValue && (
          <span className="m-dropdown__placeholder">{placeholder}</span>
        )}
        <Icon className="m-dropdown__icon" variant="dropdown" />
      </div>
      <div className="m-dropdown__options">
        {options &&
          options.length > 0 &&
          options.map((option) => (
            <div
              key={option.value}
              className="m-dropdown__option"
              onClick={() => handleSelect(option.value)}
            >
              {option.text}
            </div>
          ))}
      </div>
    </div>
  );
};

export default Dropdown;
