import { useIntl } from 'gatsby-plugin-intl';
import { Link } from 'gatsby';
import React from 'react';

type IntlLinkProps = {
  to: string;
  label?: string;
  children?: React.ReactElement;
  className?: string;
  activeClassName?: string;
  style?: any;
};

const IntlLink = ({
  to,
  label,
  className,
  activeClassName,
  style,
  children,
}: IntlLinkProps) => {
  const intl = useIntl();

  return (
    <Link
      to={`/${intl.locale}/${to}`}
      className={className}
      activeClassName={activeClassName}
      style={style}
    >
      {children ? children : label}
    </Link>
  );
};

export default IntlLink;
