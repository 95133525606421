import React from 'react';
import styled from 'styled-components';
import {
  PricingContext,
  getCurrencyName,
} from '../../providers/PricingProvider';
import Dropdown from '../dropdown';

const StyledDropdown = styled(Dropdown)`
  width: 9rem;
  .m-dropdown__value,
  .m-dropdown__placeholder {
    padding: 0.75rem;
  }
  .m-dropdown__options {
    max-height: 7rem;
  }
`;

const CurrencyDropdown = () => {
  return (
    <PricingContext.Consumer>
      {({ pricingData, setPricingData }) => {
        return (
          <StyledDropdown
            options={pricingData.currencyOptions}
            placeholder={pricingData.currentCurrency}
            defaultValue={pricingData.currentCurrency}
            currentValue={getCurrencyName(pricingData.currentCurrency)}
            className={'currency-dropdown'}
            onChange={(value) => {
              setPricingData({
                ...pricingData,
                currentCurrency: value,
              });
              localStorage.setItem(
                'currentCurrencyOption',
                JSON.stringify({ value: value, text: value })
              );
            }}
          />
        );
      }}
    </PricingContext.Consumer>
  );
};

export default CurrencyDropdown;
