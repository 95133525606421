import React from 'react';

const LandingIcons = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    style={{ display: 'none' }}
  >
    <symbol viewBox="0 0 29 39" id="landing_branding">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Desktop_Landing_Desktop"
          transform="translate(-999.000000, -2358.000000)"
          fill="#6610F2"
        >
          <g
            id="atom/icon/navigation/innovation/default-copy-9"
            transform="translate(999.000000, 2358.000000)"
          >
            <g
              id="Fill"
              opacity="0.300000012"
              transform="translate(2.000000, 2.000000)"
            >
              <polygon
                id="Path"
                points="12.8571429 14.3478261 14.2857143 0 0 18.6521739 7.14285714 18.6521739 5.71428571 33 20 14.3478261"
              ></polygon>
            </g>
            <g id="Stroke" fillRule="nonzero">
              <path
                d="M0.503539946,19.2021261 L0.40649495,19.337616 C-0.21041146,20.304046 0.470650626,21.6219907 1.65517241,21.6219907 L7.40394089,21.6208264 L6.09668052,34.2089572 C5.94346014,35.6842332 7.79466591,36.4518034 8.71813493,35.2958976 L23.4964601,16.7978739 L23.593505,16.662384 C24.2104115,15.695954 23.5293494,14.3780093 22.3448276,14.3780093 L16.5945813,14.377686 L17.9033195,1.79104279 C18.0565399,0.315766804 16.2053341,-0.451803401 15.2818651,0.704102411 L0.503539946,19.2021261 Z M14.4413793,6.50231405 L13.4858431,15.7109335 L13.4777696,15.8848218 C13.486719,16.6874278 14.1371581,17.353216 14.955665,17.353216 L19.2591133,17.3528926 L9.55714286,29.4961983 L10.5141569,20.2890665 L10.5222304,20.1151782 C10.513281,19.3125722 9.86284188,18.646784 9.04433498,18.646784 L4.73940887,18.6456198 L14.4413793,6.50231405 Z"
                id="Path"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </symbol>

    <symbol viewBox="0 0 29 39" id="landing_experience">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Desktop_Landing_Desktop"
          transform="translate(-594.000000, -2364.000000)"
          fill="#FF5500"
        >
          <g id="Group-2" transform="translate(594.000000, 2364.000000)">
            <g
              id="Fill"
              opacity="0.300000012"
              transform="translate(2.000000, 2.000000)"
            >
              <path
                d="M16.7307692,0 L16.7307692,12.2692308 L29,12.2692308 C29,5.49326923 23.5067308,0 16.7307692,0 Z"
                id="Stroke-1"
              ></path>
              <path
                d="M12.2692308,16.7307692 L12.2692308,4.46153846 C5.49326923,4.46153846 -3.67959631e-14,9.95480769 -3.67959631e-14,16.7307692 C-3.67959631e-14,23.5067308 5.49326923,29 12.2692308,29 C19.0451923,29 24.5384615,23.5067308 24.5384615,16.7307692 L12.2692308,16.7307692 Z"
                id="Stroke-3"
              ></path>
            </g>
            <g id="stroke" fillRule="nonzero">
              <path
                d="M18.7307692,0.619047619 C17.9680903,0.619047619 17.3498168,1.23732106 17.3498168,2 L17.3498168,14.2692308 C17.3498168,15.0319097 17.9680903,15.6501832 18.7307692,15.6501832 L31,15.6501832 C31.7626789,15.6501832 32.3809524,15.0319097 32.3809524,14.2692308 C32.3809524,6.73059029 26.2694097,0.619047619 18.7307692,0.619047619 Z M20.1111905,3.46795238 L20.3991121,3.50797683 C25.0765831,4.22725645 28.7727435,7.92341691 29.4920232,12.6008879 L29.5306667,12.8874286 L20.1111905,12.8874286 L20.1111905,3.46795238 Z"
                id="Stroke-1"
              ></path>
              <path
                d="M14.2692308,5.08058608 C6.73059029,5.08058608 0.619047619,11.1921288 0.619047619,18.7307692 C0.619047619,26.2694097 6.73059029,32.3809524 14.2692308,32.3809524 C21.8078712,32.3809524 27.9194139,26.2694097 27.9194139,18.7307692 L27.9101232,18.569721 C27.8303524,17.88292 27.2466634,17.3498168 26.5384615,17.3498168 L15.6493333,17.3492857 L15.6501832,6.46153846 C15.6501832,5.69885952 15.0319097,5.08058608 14.2692308,5.08058608 Z M12.8874286,7.92842857 L12.8882784,18.7307692 L12.8975691,18.8918175 C12.9773399,19.5786185 13.5610289,20.1117216 14.2692308,20.1117216 L25.0701905,20.1111905 L25.0304847,20.3991121 C24.2275679,25.6204751 19.7152226,29.6190476 14.2692308,29.6190476 C8.25594817,29.6190476 3.38095238,24.7440518 3.38095238,18.7307692 C3.38095238,13.2847774 7.37952493,8.77243208 12.6008879,7.9695153 L12.8874286,7.92842857 Z"
                id="Stroke-3"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </symbol>

    <symbol viewBox="0 0 29 39" id="landing_innovation">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Desktop_Landing_Desktop"
          transform="translate(-196.000000, -2361.000000)"
          fill="#23AA22"
        >
          <g
            id="Mesa-de-trabajo-1"
            transform="translate(196.000000, 2361.000000)"
          >
            <g
              id="Fill"
              opacity="0.300000012"
              transform="translate(2.000000, 1.000000)"
            >
              <polygon
                id="Path"
                points="16.0573477 35.8064516 8.02867384 35.8064516 6.02150538 33.8172043 6.02150538 27.8494624 18.0645161 27.8494624 18.0645161 33.8172043"
              ></polygon>
              <path
                d="M18.0645161,27.8494624 L18.0645161,25.522043 C18.0355535,23.4565222 18.8541131,21.4678804 20.3326165,20.011828 C22.6913899,17.9754348 24.057629,15.0356463 24.0860215,11.9354839 C24.0860215,5.34369815 18.6941819,9.79903027e-13 12.0430108,9.79903027e-13 C5.39183957,9.79903027e-13 1.17091522e-12,5.34369815 1.17091522e-12,11.9354839 C0.0360324029,15.0382087 1.40935057,17.9775114 3.7734767,20.011828 C5.24658583,21.4699079 6.05808434,23.4589978 6.02150538,25.522043 L6.02150538,27.8494624"
                id="Path"
              ></path>
            </g>
            <g id="Stroke" fillRule="nonzero">
              <path
                d="M20.1954459,27.5985663 L7.86907021,27.5985663 C6.9269374,27.5985663 6.16318786,28.3447584 6.16318786,29.265233 L6.16318786,35.1792115 C6.16318786,35.6264442 6.34715971,36.0549122 6.67374477,36.3682917 L8.72814072,38.3396179 C9.0471271,38.645706 9.47636097,38.8172043 9.92346616,38.8172043 L18.14105,38.8172043 C18.5881552,38.8172043 19.017389,38.645706 19.3363754,38.3396179 L21.3907714,36.3682917 C21.7173564,36.0549122 21.9013283,35.6264442 21.9013283,35.1792115 L21.9013283,29.265233 C21.9013283,28.3447584 21.1375787,27.5985663 20.1954459,27.5985663 Z M18.4883529,30.9316667 L18.4883529,34.48 L17.4426471,35.4833333 L10.6208235,35.4833333 L9.57341176,34.48 L9.57341176,30.9316667 L18.4883529,30.9316667 Z"
                id="Path"
              ></path>
              <path
                d="M14.0322581,1.51523238e-12 C21.7752487,1.51523238e-12 28.0644433,6.03495998 28.0644433,13.5100258 C28.0308129,17.0653711 26.4136989,20.4343587 23.70838,22.6909792 C22.6198707,23.7288808 21.9814329,25.113504 21.9078813,26.5883411 L21.9013283,26.9587814 L21.9013283,29.265233 L18.4895636,29.265233 L18.4897343,26.9823614 C18.4534516,24.4770554 19.4780816,22.0669128 21.407931,20.2317836 C23.4486757,18.5259626 24.6283335,16.0683422 24.6527514,13.4946237 C24.6527514,7.88948679 19.904606,3.33333333 14.0322581,3.33333333 C8.15991009,3.33333333 3.41176471,7.88948679 3.41164758,13.4750938 C3.44273922,16.0672607 4.62893021,18.5253471 6.76268943,20.308377 C8.49590243,21.9693697 9.49810607,24.2035569 9.57202022,26.5230048 L9.57495256,29.265233 L6.16318786,29.265233 L6.16346083,26.9289665 C6.19249369,25.3435751 5.54760628,23.8131221 4.46126992,22.7663892 C1.66785802,20.4390898 0.0427859354,17.0715313 1.55075952e-12,13.4946237 C1.55075952e-12,6.03495998 6.28926739,1.51523238e-12 14.0322581,1.51523238e-12 Z"
                id="Path"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </symbol>

    <symbol id="check" viewBox="0 0 14 13">
      <g id="Icon" transform="translate(0,0)" fill="currentColor">
        <path d="M3.52820584,7.46223537 L6.6960787,10.5091421 L6.6960787,10.5091421 L12.4803129,4.51918319 C12.7469443,4.24306866 13.1869268,4.23538107 13.4630414,4.50201246 C13.469331,4.50808609 13.4755055,4.51427787 13.4815615,4.5205845 L13.5377522,4.57910067 C13.7960203,4.84805692 13.7960203,5.27289948 13.5377522,5.54185573 L6.6960787,12.6666667 L6.6960787,12.6666667 L2.48356396,8.54232327 C2.21444218,8.27883443 2.20417242,7.84892697 2.46040579,7.57288829 L2.53705072,7.49031917 C2.79818533,7.20900041 3.23793067,7.19263792 3.51924943,7.45377254 C3.52225985,7.45656696 3.52524543,7.45938802 3.52820584,7.46223537 Z"></path>
      </g>
    </symbol>
    <symbol id="close" viewBox="0 0 14 14">
      <g transform="translate(0, 0)" fill="currentColor">
        <path d="M12.3160343,2.66666667 L13.3333333,3.68396571 L9.01666667,7.99966667 L13.3333333,12.3160343 L12.3160343,13.3333333 L7.99966667,9.01666667 L3.68396571,13.3333333 L2.66666667,12.3160343 L6.98266667,7.99966667 L2.66666667,3.68396571 L3.68396571,2.66666667 L7.99966667,6.98266667 L12.3160343,2.66666667 Z"></path>
      </g>
    </symbol>
  </svg>
);

export default LandingIcons;
