/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const intl = useIntl();

  const metaDescription =
    description ||
    intl.formatMessage({ id: 'meta_description' }) ||
    site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `facebook-domain-verification`,
          content: `bq9z4rngf0a89v3u4g4m3s3nvnqbkw`,
        },
        {
          name: `twitter:site`,
          content: `@deeptrueHQ`,
        },
        {
          name: `twitter:image`,
          content: `https://images.prismic.io/deeptrue/d2aae904-b075-414a-9a1a-608fcf32010c_Logo_Desktop_EN.png?ixlib=gatsbyFP&auto=compress%2Cformat&fit=max&q=50`,
        },
        {
          name: `og:image`,
          content: `https://images.prismic.io/deeptrue/d2aae904-b075-414a-9a1a-608fcf32010c_Logo_Desktop_EN.png?ixlib=gatsbyFP&auto=compress%2Cformat&fit=max&q=50`,
        },
      ].concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
