import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import React, { useRef, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import IntlLink from '../link';

import Language from '../language';
import Logo from '../Logo';
import fbPixel from '../../utils/fbPixel';
import { hooks } from '@deeptrue-frontend/ui';
import { useAuth } from '../../providers/AuthProvider';

const ENV =
  process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development';
const APP_URL =
  ENV === 'development' ? 'app-dev.deeptrue.com' : 'app.deeptrue.com';

type ProductType = {
  tool_main_description: string;
  is_new: boolean;
  is_prod: boolean;
  is_product_free: boolean;
  tool: {
    document: {
      data: {
        title: string;
        tool_django_slug: string;
      };
    };
  };
};

type ToolType = {
  is_new: boolean;
  is_prod: boolean;
  name: string;
  slug: string;
};

type NavbarChildProps = {
  name: string;
  iconUrl: string;
  slug: string;
  products: [ProductType];
  description?: string;
  lang?: string;
};
const NavbarChild = ({
  name,
  iconUrl,
  slug,
  products,
  description,
}: NavbarChildProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const tools = products
    .filter(({ tool }) => tool.document)
    .map(({ tool, is_new, is_prod, is_product_free }) => {
      return {
        slug: tool.document.data.tool_django_slug,
        name: tool.document.data.title,
        is_new,
        is_prod,
        is_product_free,
      };
    });

  const ENV =
    process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development';

  const isProduction = ENV !== 'development';

  // NOTE do not know yet if we should add also flag for dev only
  const filteredToolsByEnvironment = tools.filter((tool: ToolType) =>
    isProduction ? tool.is_prod : true
  );

  return (
    <li
      className={`navigation__child navigation__child--products ${
        isOpen ? 'navigation__child--open' : ''
      }`}
    >
      <span
        className="navigation__child-parent"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span id={slug}>
          <IntlLink to={`workbenchs/${slug}`}>
            <>
              <embed
                src={iconUrl}
                type="image/svg+xml"
                style={{ display: 'none' }}
              />
              <svg className="navigation__icon">
                <use xlinkHref={`#landing_${slug}`} />
              </svg>
              {name}
            </>
          </IntlLink>
        </span>
        <svg className="navigation__arrow">
          <use xlinkHref="#chevron-bottom"></use>
        </svg>
      </span>
      <span className="navigation__child-content navigation__child-content--open">
        {description && (
          <span
            className="navigation__child-description"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
        {/* <pre>{JSON.stringify(products, null, 2)}</pre> */}
        {products && products.length > 0 && (
          <ul className="navigation__child-items">
            {filteredToolsByEnvironment.map((product, idx) => (
              <li
                className="navigation__child-item"
                key={`navigation-child$-${product.slug}-${idx}`}
              >
                <IntlLink to={`products/${product.slug}`}>
                  <>
                    {product.name}{' '}
                    <svg className="navigation__arrow">
                      <use xlinkHref="#chevron-right"></use>
                    </svg>
                    {product.is_new && (
                      <span className="navigation__tag navigation__tag--new">
                        New
                      </span>
                    )}
                    {product.is_product_free && (
                      <span className="navigation__tag navigation__tag--free">
                        Free
                      </span>
                    )}
                  </>
                </IntlLink>
              </li>
            ))}
          </ul>
        )}
      </span>
    </li>
  );
};
const Header = (props) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [productsOpen, setProductsOpen] = useState(false);
  const wrapperRef = useRef<HTMLLIElement>(null);
  const { locale } = useIntl();
  const { authData } = useAuth();
  const isAuthenticated = !!authData.user.username;
  hooks.useOnClickOutside(wrapperRef, () => setProductsOpen(false));
  const { allPrismicWorkbenches, allPrismicMenu } = useStaticQuery(
    graphql`
      query WorkbenchesHeader($prismicLang: String) {
        allPrismicWorkbenches(filter: { lang: { eq: $prismicLang } }) {
          nodes {
            lang
            data {
              body {
                ... on PrismicWorkbenchesDataBodyWorkbench {
                  id
                  primary {
                    is_prod
                    workbench_slug
                    workbench_name
                    workbench_icon {
                      url
                    }
                    workbench_description
                    workbench_cover {
                      url
                    }
                    tools_title
                    tools_headline
                  }
                  items {
                    tool_main_description
                    is_new
                    is_prod
                    is_product_free
                    tool {
                      slug
                      document {
                        ... on PrismicToolDetail {
                          data {
                            title
                            tool_django_slug
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        allPrismicMenu(filter: { lang: { eq: $prismicLang } }) {
          edges {
            node {
              data {
                menu {
                  link_text
                  url
                }
              }
              lang
            }
          }
        }
      }
    `
  );

  const menuLinks = allPrismicMenu.edges.find(({ node }) => {
    return node.lang.search(`${locale}-`) >= 0;
  }).node.data.menu;

  const products = allPrismicWorkbenches.nodes.find(
    (node) => node.lang.search(`${locale}-`) >= 0
  ).data.body;
  const isProduction = ENV !== 'development';

  // NOTE do not know yet if we should add also flag for dev only
  const filteredProductsByEnvironment = products.filter((product: any) =>
    isProduction ? product.primary.is_prod : true
  );

  return (
    <header className={`header ${!isCollapsed ? 'header--is-open' : ''}`}>
      <div className="header__wrap">
        <IntlLink to="" className="header__brand">
          <Logo />
        </IntlLink>

        <div className="header__toggle-menu">
          {isCollapsed && (
            <button
              className="button button--outline"
              onClick={() => setIsCollapsed(!isCollapsed)}
            >
              Menu
            </button>
          )}
          {!isCollapsed && (
            <button
              className="button button--outline"
              onClick={() => setIsCollapsed(!isCollapsed)}
            >
              Close{' '}
              <svg>
                <use xlinkHref="#cross"></use>
              </svg>
            </button>
          )}
        </div>

        <nav
          className={`navigation ${!isCollapsed ? 'navigation--active' : ''}`}
        >
          <ul className="navigation__items">
            <li
              ref={wrapperRef}
              className={`navigation__item ${
                productsOpen ? 'navigation__item--open' : ''
              }
            `}
            >
              <span
                className={`navigation__item-parent ${
                  productsOpen ? 'active' : ''
                }`}
                onClick={() => setProductsOpen(!productsOpen)}
              >
                <FormattedMessage id="Products" />
                <svg
                  className={`navigation__arrow ${
                    productsOpen ? 'navigation__arrow--open' : ''
                  }`}
                >
                  <use xlinkHref="#chevron-bottom"></use>
                </svg>
              </span>
              <ul className="navigation__childs">
                <span className="navigation__childs-wrap">
                  {filteredProductsByEnvironment.map((node) => (
                    <NavbarChild
                      key={`product_menu-wb-${node.id}`}
                      name={node.primary.workbench_name}
                      iconUrl={node.primary.workbench_icon}
                      slug={node.primary.workbench_slug}
                      description={node.primary.workbench_description}
                      products={node.items}
                      lang={locale}
                    />
                  ))}
                </span>
              </ul>
            </li>
            <li className="navigation__item">
              <IntlLink
                className="navigation__item-link"
                to="about"
                activeClassName="active"
              >
                <FormattedMessage id="about_us" />
              </IntlLink>
            </li>
            {menuLinks.map((menuLinkDetails) => (
              <li className="navigation__item" key={menuLinkDetails.url}>
                <IntlLink
                  className="navigation__item-link"
                  to={`${menuLinkDetails.url}`}
                  activeClassName="active"
                >
                  {menuLinkDetails.link_text}
                </IntlLink>
              </li>
            ))}
            <li className="navigation__item">
              <IntlLink
                className="navigation__item-link"
                to={`blog`}
                activeClassName="active"
                label="Blog"
              />
            </li>

            <li className="navigation__item">
              <Language
                className="m-language-chooser--header"
                alternativeLanguages={props.alternativeLanguages}
              />
            </li>
          </ul>
          <div className="navigation__items__separator" />
          <ul className="navigation__items navigation__items--auth">
            {!isAuthenticated ? (
              <>
                <li className="navigation__item navigation__item">
                  <a
                    className="button button--full"
                    href={`https://${APP_URL}/sign-up?lang=${locale}`}
                    hrefLang={locale}
                    id="sign-up"
                    onClick={() => fbPixel('Lead')}
                  >
                    <FormattedMessage id="sign-up" />
                  </a>
                </li>
                <li className="navigation__item navigation__item">
                  <a
                    className="navigation__item--login navigation__item-link navigation__item-link--unbordered navigation__item-link--centered"
                    href={`https://${APP_URL}/sign-in?lang=${locale}`}
                    hrefLang={locale}
                  >
                    <FormattedMessage id="log-in" />
                  </a>
                </li>
              </>
            ) : (
              <>
                <li className="navigation__item navigation__item">
                  <a
                    className="button button--full"
                    href={`https://${APP_URL}`}
                    hrefLang={locale}
                  >
                    <FormattedMessage id="Dashboard" />
                  </a>
                </li>
                <li className="navigation__item navigation__item">
                  <a
                    className="navigation__item--login navigation__item-link navigation__item-link--unbordered navigation__item-link--centered"
                    href={`https://${APP_URL}/settings/profile`}
                    hrefLang={locale}
                  >
                    {
                      (authData.user as {
                        username: string;
                      }).username
                    }
                  </a>
                </li>
              </>
            )}
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
