import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { useIntl } from 'gatsby-plugin-intl';
import IntlLink from '../link';

const CookieBanner = () => {
  const intl = useIntl();
  return (
    <CookieConsent
      location="bottom"
      buttonText={intl.formatMessage({ id: 'cookie_agree' })}
      cookieName="dt-gdpr-google-analytics"
      disableStyles
      containerClasses="cookie-consent"
      contentClasses="cookie-consent__content"
      expires={150}
      onAccept={() => {
        // eslint-disable-next-line no-undef
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          // eslint-disable-next-line prefer-rest-params
          dataLayer.push(arguments); // eslint-disable-line no-undef
        }
        // eslint-disable-next-line no-undef
        gtag('js', new Date());

        // eslint-disable-next-line no-undef
        gtag('config', 'UA-135194502-1');
      }}
    >
      {intl.formatMessage({ id: 'cookie_desc' })}{' '}
      <IntlLink
        to="privacy-policy"
        style={{ color: '#1F5DEA' }}
        label={intl.formatMessage({ id: 'cookie_more-info' })}
      />
    </CookieConsent>
  );
};

export default CookieBanner;
