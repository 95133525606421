import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Language from '../language/footerLanguage';
import NewsletterForm from '../newsletterForm/index';
import { Typography } from '@deeptrue-frontend/ui';
import CurrencyDropdown from '../currencyDropdown';
import IntlLink from '../link';
interface Props {
  isDisabledSocial?: boolean;
  isDisabledMenu?: boolean;
  isDisabladNewsletter?: boolean;
  isDisabledFAQ?: boolean;
  isDisabledAboutUs?: boolean;
  lang: string;
  alternativeLanguages?: {
    path: string;
    languages: { lang: string; uid: string }[];
  };
}

function filterDataByLang(data, lang) {
  for (let i = 0; i < data.length; i++) {
    if (data[i].node.lang.includes(lang)) {
      return data[i].node.data;
    }
  }
}

const Footer = ({
  isDisabledSocial,
  isDisabledMenu,
  isDisabladNewsletter,
  isDisabledFAQ,
  isDisabledAboutUs,
  lang,
  alternativeLanguages,
}: Props) => {
  return (
    <StaticQuery
      query={graphql`
        query FooterQuery($lang: String) {
          allPrismicCommonInformation(filter: { lang: { eq: $lang } }) {
            edges {
              node {
                lang
                data {
                  footer_about_us_link_text
                  footer_about_us_link_url
                  footer_copyright_text
                  footer_faq_link_text
                  footer_faq_link_url
                  footer_imprint_link_text
                  footer_imprint_link_url
                  footer_main_text
                  footer_main_title
                  footer_newsletter_text
                  footer_newsletter_title
                  footer_newsletter_cta_text
                  footer_newsletter_form_placeholder
                  footer_privacy_link_text
                  footer_privacy_link_url
                  footer_terms_link_text
                  footer_terms_link_url
                  footer_workbenches_links {
                    footer_link_is_blue
                    footer_workbench_link_title
                    footer_workbench_link_url
                  }
                  footer_workbenches_title
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const filteredData = filterDataByLang(
          data.allPrismicCommonInformation.edges,
          lang
        );

        return (
          <footer className="o-footer">
            <div className="o-footer__wrap">
              <div className="o-footer__brand">
                <div className="o-footer__logo">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 475.98 95.41"
                  >
                    <title>{filteredData.footer_main_title}</title>
                    <path d="M56.37,0a7,7,0,0,0-7,6.84h0V21.52A31.71,31.71,0,1,0,63.42,47.86h0v-41h-.05A7,7,0,0,0,56.37,0ZM31.71,65.52A17.66,17.66,0,1,1,49.37,47.86,17.66,17.66,0,0,1,31.71,65.52ZM236.44,17.66a31.71,31.71,0,0,0-31.71,31.71h0v41h.05a7,7,0,0,0,14,0h0V75.71a31.71,31.71,0,1,0,17.66-58Zm0,49.37A17.66,17.66,0,1,1,254.1,49.37,17.66,17.66,0,0,1,236.44,67ZM400.56,16.87a7,7,0,0,0-7,6.84h0V52.16h0a14,14,0,0,1-28,0h0V23.71h-.05a7,7,0,0,0-14,0h0V52.16a28,28,0,0,0,56.06,0V23.71h-.05A7,7,0,0,0,400.56,16.87Zm-60.84-.51v-.08h-3.35a27,27,0,0,0-27,27V73.35h.05a7,7,0,0,0,14,0h0V43.29h0a12.91,12.91,0,0,1,12.92-12.92h3.35a7,7,0,1,0,0-14Zm-42,15.57v-.05a7,7,0,0,0,0-14H287V7.1h0a7,7,0,0,0-14,0h0V59.54a20.65,20.65,0,0,0,20.65,20.65h4.07v0a7,7,0,0,0,0-14h-4.07a6.6,6.6,0,0,1-6.6-6.6h0V31.93ZM100,16.77A31.71,31.71,0,1,0,122.37,70.9l0,0A7,7,0,0,0,112.43,61,17.56,17.56,0,0,1,100,66.14c-7.4,0-15.36-4.58-17.19-12H125c3.66,0,6.63-2,6.63-5.66h0A31.71,31.71,0,0,0,100,16.77ZM82.76,42.24c2.17-7.82,9.42-12.32,17.19-12.32,9.44,0,15.29,5.29,16.92,12Zm85.43-25.47A31.71,31.71,0,1,0,190.62,70.9l0,0a7,7,0,0,0-9.9-9.91,17.6,17.6,0,0,1-12.49,5.18c-7.4,0-15.35-4.58-17.19-12h42.27c3.66,0,6.63-2,6.63-5.66h0A31.71,31.71,0,0,0,168.19,16.77ZM151,42.24c2.17-7.82,9.42-12.32,17.19-12.32,9.45,0,15.29,5.29,16.93,12ZM444.14,16.77A31.71,31.71,0,1,0,466.56,70.9l0,0A7,7,0,0,0,456.62,61a17.56,17.56,0,0,1-12.48,5.18c-7.4,0-15.36-4.58-17.19-12h42.27c3.66,0,6.63-2,6.63-5.66h0A31.71,31.71,0,0,0,444.14,16.77ZM427,42.24c2.17-7.82,9.42-12.32,17.19-12.32,9.44,0,15.29,5.29,16.92,12Z"></path>
                  </svg>
                </div>
                <Typography>{filteredData.footer_main_text}</Typography>
              </div>

              {!isDisabledMenu && (
                <div className="o-footer__products">
                  <h3 className="o-footer__headline">
                    {filteredData.footer_workbenches_title}
                  </h3>
                  {filteredData.footer_workbenches_links &&
                    filteredData.footer_workbenches_links.map((link) => {
                      // HACK TO AVOID GATSBY CONSOLE ERROR, footer_link_is_blue used only for Support
                      const {
                        footer_link_is_blue,
                        footer_link_is_blue: isExternalLink,
                        footer_workbench_link_url,
                        footer_workbench_link_title,
                      } = link;

                      return (
                        <h4
                          className={
                            footer_link_is_blue
                              ? 'o-footer__title o-footer__title--blue'
                              : 'o-footer__title'
                          }
                          key={footer_workbench_link_title}
                        >
                          {isExternalLink ? (
                            <a
                              href={footer_workbench_link_url}
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              {footer_workbench_link_title}
                            </a>
                          ) : (
                            <IntlLink to={footer_workbench_link_url}>
                              {footer_workbench_link_title}
                            </IntlLink>
                          )}
                        </h4>
                      );
                    })}
                </div>
              )}
              {!isDisabladNewsletter && (
                <div className="o-footer__newsletter">
                  <h3 className="o-footer__headline">
                    {filteredData.footer_newsletter_title}
                  </h3>
                  <div style={{ marginBottom: '1rem' }}>
                    <Typography>
                      {filteredData.footer_newsletter_text}
                    </Typography>
                  </div>
                  <NewsletterForm
                    data={{
                      placeholder:
                        filteredData.footer_newsletter_form_placeholder,
                      ctaText: filteredData.footer_newsletter_cta_text,
                    }}
                  />
                </div>
              )}

              <div className="o-footer__links">
                <nav className="o-footer__nav">
                  <ul className="o-footer__nav-list">
                    {!isDisabledAboutUs && (
                      <li className="o-footer__nav-list-item">
                        <IntlLink to={filteredData.footer_about_us_link_url}>
                          {filteredData.footer_about_us_link_text}
                        </IntlLink>
                      </li>
                    )}
                    {!isDisabledFAQ && (
                      <li className="o-footer__nav-list-item">
                        <IntlLink to={filteredData.footer_faq_link_url}>
                          {filteredData.footer_faq_link_text}
                        </IntlLink>
                      </li>
                    )}
                    <li className="o-footer__nav-list-item">
                      <IntlLink to={filteredData.footer_terms_link_url}>
                        {filteredData.footer_terms_link_text}
                      </IntlLink>
                    </li>
                    <li className="o-footer__nav-list-item">
                      <IntlLink to={filteredData.footer_imprint_link_url}>
                        {filteredData.footer_imprint_link_text}
                      </IntlLink>
                    </li>
                    <li className="o-footer__nav-list-item">
                      <IntlLink to={filteredData.footer_privacy_link_url}>
                        {filteredData.footer_privacy_link_text}
                      </IntlLink>
                    </li>
                  </ul>
                </nav>
              </div>

              {!isDisabledSocial && (
                <div className="o-footer__social">
                  <ul className="m-social-links">
                    <li className="m-social-links__item">
                      <a
                        href="https://www.linkedin.com/company/deeptrue"
                        rel="noreferrer"
                        target="_blank"
                        title="LinkedIn"
                      >
                        <svg className="m-social-links__icon">
                          <use xlinkHref="#linkedin"></use>
                        </svg>
                        <span className="m-social-links__desc">LinkedIn</span>
                      </a>
                    </li>
                    <li className="m-social-links__item">
                      <a
                        href="https://web.facebook.com/deeptruecom"
                        target="_blank"
                        rel="noreferrer"
                        title="Facebook"
                      >
                        <svg className="m-social-links__icon">
                          <use xlinkHref="#facebook"></use>
                        </svg>
                        <span className="m-social-links__desc">Facebook</span>
                      </a>
                    </li>
                    <li className="m-social-links__item">
                      <a
                        href="https://medium.com/@deeptrue"
                        target="_blank"
                        rel="noreferrer"
                        title="Medium"
                      >
                        <svg className="m-social-links__icon">
                          <use xlinkHref="#medium"></use>
                        </svg>
                        <span className="m-social-links__desc">Medium</span>
                      </a>
                    </li>
                    <li className="m-social-links__item">
                      <a
                        href="https://twitter.com/deeptrueHQ"
                        target="_blank"
                        rel="noreferrer"
                        title="Twitter"
                      >
                        <svg className="m-social-links__icon">
                          <use xlinkHref="#twitter"></use>
                        </svg>
                        <span className="m-social-links__desc">Twitter</span>
                      </a>
                    </li>
                  </ul>
                </div>
              )}

              <div className="o-footer__language">
                <Language alternativeLanguages={alternativeLanguages} />
                <CurrencyDropdown />
              </div>

              <div className="o-footer__copyright">
                <p>{filteredData.footer_copyright_text}</p>
              </div>
            </div>
          </footer>
        );
      }}
    />
  );
};

export default Footer;
