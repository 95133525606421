/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import './layout.css';
import {
  theme,
  GlobalStyles,
  SurveyGlobalStyles,
  Icons,
} from '@deeptrue-frontend/ui';
import { ThemeProvider } from 'styled-components';
import { injectIntl } from 'gatsby-plugin-intl';

import Header from '../components/header/header';
import LandingIcons from '../components/landing/LandingIcons';
import Footer from '../components/footer/footer';
import CookieBanner from '../components/cookieBanner';

const Layout = (props) => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Icons />
        <LandingIcons />
        <CookieBanner />
        {!props.isBexio && (
          <Header alternativeLanguages={props.alternativeLanguages} />
        )}
        <main>{props.children}</main>
        <Footer
          isDisabledMenu={props.isBexio}
          isDisabledSocial={props.isBexio}
          isDisabladNewsletter={props.isBexio}
          isDisabledFAQ={props.isBexio}
          isDisabledAboutUs={props.isBexio}
          lang={props.lang}
          alternativeLanguages={props.alternativeLanguages}
        />
        <GlobalStyles />
        <SurveyGlobalStyles />
      </ThemeProvider>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isBexio: PropTypes.bool,
  footerProps: PropTypes.object,
  lang: PropTypes.string,
  alternativeLanguages: PropTypes.object,
};
Layout.defaultProps = {
  isBexio: false,
};

export default injectIntl(Layout);
